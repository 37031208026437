// buyer_dom_white_againRefund
const __request = require(`./__request/__request_contentType_json`);
const buyer_dom_white_againRefund = (pParameter) => {
    if (!pParameter) pParameter = {};
    pParameter = {
        method: 'post',
        urlSuffix: '/buyer/dom/white/againRefund',
        data: pParameter
    }

    return __request(pParameter)
}
module.exports = buyer_dom_white_againRefund;